<template>
  <div class="nav-item-dropdown h-full">
    <component
      :is="setLink(menu)"
      class="group relative flex cursor-pointer select-none items-center gap-2 text-sm xl:text-base py-4 xl:py-[22px] px-2 border-b-4 border-transparent h-full"
      v-bind="setAdditionalAttributes(menu)"
      :class="{
        '!border-p-500': isSelectedGroup,
        'dropdown-trigger': menu.groups && menu.groups.length,
      }"
      no-prefetch
      tabindex="0"
      @click="handleClick(menu.modal)"
    >
      <span
        class="text-s-900 font-bold transition-all duration-300 group-hover:text-opacity-60 leading-none"
        data-testid="navigation-menu"
      >
        {{ menu.name }}
      </span>
      <CommonTriangleDown
        v-if="menu.groups && menu.groups.length"
        class="transition duration-300 group-focus:rotate-180"
      />
    </component>

    <!-- Submenu -->
    <div
      v-if="menu.groups && menu.groups.length"
      class="dropdown-menu top-10 z-50 -translate-x-1/2 pt-6"
      :class="[isProductsAndServices ? 'left-[55%] xl:left-1/2' : 'left-1/2']"
    >
      <!-- Submenu Arrow -->
      <div
        class="absolute left-1/2 top-4 z-[100] h-4 w-4 -translate-x-1/2 rotate-45 bg-white shadow-[-3px_-5px_10px_-1px_rgba(0,0,0,0.1)]"
      />
      <div class="sm-height:max-h-[60vh]">
        <div
          data-testid="navigation-item-container"
          class="text-s-900 flex max-h-[550px] flex-col flex-wrap gap-x-6 gap-y-6 rounded-xl bg-white p-6 shadow-[3px_3px_10px_rgba(0,0,0,0.25)] xl:max-h-[560px] xl:p-8"
          :class="{
            'xl:min-w-content-container min-w-[980px]': isProductsAndServices,
          }"
        >
          <div
            v-for="group in menu.groups"
            :key="`${menu.name}_${group.name}`"
            class="min-w-[215px]"
            :class="group.extraClass"
          >
            <div class="block">
              <div class="w-full">
                <h4
                  class="border-p-500 font-montserrat text-s-900 mb-3 border-b-[3px] pb-3 text-sm font-semibold uppercase xl:text-base xl:leading-5"
                >
                  {{ group.name }}
                </h4>

                <div
                  class="flex flex-col gap-y-1.5"
                  :class="{ 'flex !flex-row gap-x-3': group.hasSubMenu }"
                >
                  <div
                    v-for="child in group.children"
                    :key="`${group.name}_${child.name}`"
                  >
                    <!-- Sub Menu -->
                    <ItemSubMenu
                      v-if="child.children?.length"
                      :title="child.name"
                      :sub-menu="child.children"
                      @close="closeMenu"
                    />
                    <!-- Modal -->
                    <div
                      v-else-if="child.modal && checkIfCallback(child)"
                      class="mb-2 cursor-pointer font-normal hover:underline"
                      @click="handleClick(child.modal, child['initial-fields'])"
                    >
                      {{ child.name }}
                    </div>

                    <!-- Link -->
                    <div
                      v-else-if="!child.modal && checkIfCallback(child)"
                      class="flex gap-x-1.5"
                    >
                      <Icon
                        v-if="child.icon === 'source'"
                        name="sidebar/Terminal"
                        class="h-3.5 w-4"
                      />
                      <component
                        :is="setLink(child)"
                        v-bind="setAdditionalAttributes(child, group.href)"
                        data-testid="navigation-item"
                        :class="{
                          'bg-s-200': isSelected(child.href, group.href),
                        }"
                        class="font-montserrat hover:bg-s-200 flex items-center justify-between rounded-lg text-sm font-normal xl:text-base xl:leading-5"
                        @click="closeMenu"
                      >
                        <span class="max-w-[200px]">
                          {{ child.name }}
                        </span>
                        <span
                          v-if="hasAnyActiveMembership(child.memberships)"
                          class="h-4 w-4"
                        >
                          <Icon
                            name="check-subscribe"
                            class="text-p-500 h-full w-full"
                          />
                        </span>
                      </component>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '~/components/common/Icon.vue'
import CommonTriangleDown from '~/components/common/TriangleDown.vue'
import ItemSubMenu from '~/components/navigation/ItemSubMenu.vue'
import SourceIcon from '~/assets/icons/sidebar/Terminal.svg'
import { mapStores } from 'pinia'

import { useMembershipsStore } from '~/stores/memberships'

export default {
  components: {
    Icon,
    CommonTriangleDown,
    ItemSubMenu,
    SourceIcon,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },

    isSelectedGroup: {
      type: Boolean,
      default: false,
    },

    onClick: {
      type: Function,
      default: null,
    },
  },

  data() {
    return {
      menu: this.data,
      isProductsAndServices: this.data.name === 'Products & Services',
    }
  },

  computed: {
    ...mapStores(useMembershipsStore),
    isOpen() {
      return this.menu?.groups?.length && this.menu?.open
    },
  },

  created() {
    this.menu.open = false
    if (import.meta.client) {
      window.addEventListener(`click`, this.closePopup)
    }
  },

  beforeUnmount() {
    if (import.meta.client) {
      window.removeEventListener(`click`, this.closePopup)
    }
  },

  methods: {
    isSelected(menuPath, base = '') {
      const { fullPath } = this.$route
      const childPath = menuPath.replace('/', '')

      if (childPath === '') return fullPath === base

      return (
        fullPath.includes(base) &&
        fullPath?.split('/')?.some((_item) => _item === childPath)
      )
    },

    checkIfCallback(child) {
      if (!child.if_callback) {
        return true
      }

      return child.if_callback()
    },

    isExternalLink(element) {
      return element?.href?.startsWith('https')
    },

    setLink(element) {
      if (this.isExternalLink(element)) {
        return 'a'
      }

      if (this.hasLink(element)) {
        return this.hasTarget(element) && typeof element.target === 'string'
          ? 'a'
          : resolveComponent('NuxtLink')
      }

      return 'div'
    },

    hasLink(element) {
      return !!element.href
    },

    hasTarget(element) {
      return 'target' in element
    },

    setAdditionalAttributes(element, base = '') {
      if (!this.hasLink(element)) return {}

      const OVERVIEW = 'Overview'
      let url = `${base}${element.href}`
      if (element.firstItem && element.firstItem !== OVERVIEW) {
        url += `/${element.firstItem}`
      }

      return this.isExternalLink(element)
        ? {
            href: element.href,
            target: element.target ? element.target : '_self',
          }
        : { to: url }
    },

    handleClick(modal, initialFields) {
      if (!modal) return this.toggle()

      if (initialFields) {
        this.$modal.setInitialFields(initialFields)
      }

      this.$modal.open(modal)
    },

    toggle() {
      this.menu.open = !this.menu.open
    },

    closeMenu() {
      this.menu.open = false
    },

    /**
     * Close the menu if the user clicks outside the menu
     */
    closePopup(e) {
      const { $el } = this
      const { target } = e

      if (!$el.contains(target)) {
        this.menu.open = false
      }
    },

    hasAnyActiveMembership(memberships) {
      if (memberships) {
        return memberships.find((membership) =>
          this.membershipsStore.getSubscription(membership),
        )
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.nav-item-dropdown {
  position: relative;

  .dropdown-menu:active {
    display: block;
    opacity: 1;
  }

  &:has(.dropdown-trigger:focus)::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}

.dropdown-trigger {
  position: relative;

  &:focus + .dropdown-menu {
    visibility: visible;
    opacity: 1;
  }
}

.dropdown-menu {
  position: absolute;
  top: 2.5rem;
  opacity: 0;
  visibility: hidden;
  transition:
    visibility 0.3s,
    opacity 0.3s ease;
}
</style>
