<template>
  <ModalV2
    name="contact"
    modal-classes="max-w-contact-form-container h-fit max-h-[85vh] min-h-[350px] lg:max-h-[740px] !p-0 bg-white"
    content-classes="!overflow-hidden"
    :is-open="isContactModalOpen"
    @close="closeModal"
  >
    <ContactFormWithSideImage :loading="loading" />
  </ModalV2>
</template>

<script setup lang="ts">
/** components */
import ModalV2 from '~/components/common/ModalV2.vue'
import ContactFormWithSideImage from '~/components/contact/ContactFormWithSideImage.vue'
import { useModalStore } from '~/stores/modal'
import { useAuthStore } from '~/stores/auth'
import { useHubSpotUser } from '~/composables/useHubSpotUser'

const authStore = useAuthStore()
const { close, modals } = useModalStore()
const { fetchUserInfo } = useHubSpotUser()

const loading = ref(false)
const authenticated = computed(() => authStore.authenticated)
const isContactModalOpen = computed(() => modals.contactOpen)

const closeModal = () => close('contact')

watch(
  [isContactModalOpen, authenticated],
  async ([isContactModalOpen, authenticated]) => {
    loading.value = true
    if (!isContactModalOpen || !authenticated) {
      loading.value = false
      return
    }
    await fetchUserInfo()
    loading.value = false
  },
  {
    immediate: true,
  },
)
</script>
