<template>
  <div
    class="w-full grid grid-cols-1 grid-rows-2 xl:flex justify-between items-center gap-x-2"
  >
    <div class="col-span-3 xl:col-span-1">
      <NuxtLink to="/" class="flex pt-2 pb-5 w-fit" no-prefetch>
        <BenchmarkLogo class="w-[226px] h-[34px]" />
      </NuxtLink>
    </div>

    <div class="flex items-center gap-x-1 h-full justify-end w-full xl:w-fit">
      <NavigationItem
        v-for="(menu, index) in computedMenuList"
        :key="getKey(menu)"
        :data="menu"
        :is-selected-group="activeMenuIndex === index"
        data-testid="top-navigation-item"
      />
    </div>
    <div class="flex gap-x-3 justify-end">
      <ButtonSource />
      <ButtonContactUs class="py-2 px-4 leading-none" />
    </div>
  </div>
</template>
<script>
/** packages */
import { mapStores } from 'pinia'

/** components */
import NavigationItem from '~/components/navigation/Item.vue'
import ButtonSource from '~/components/navigation/SourceButton.vue'
import ButtonContactUs from '~/components/shared/common/ButtonContactUs.vue'
import BenchmarkLogo from '~/assets/icons/benchmark-logo.svg'

/** constants */
import { DESKTOP_MENU } from '~/constants/main-menu'

/** stores */
import { useMenuStore } from '~/stores/menu'
import { useBreadcrumbStore } from '~/stores/breadcrumb'

export default {
  components: {
    ButtonSource,
    ButtonContactUs,
    NavigationItem,
    BenchmarkLogo,
  },
  data() {
    return {
      menuList: DESKTOP_MENU,
      sourceLink: this.$config.public.sourceUrl,
    }
  },
  computed: {
    ...mapStores(useMenuStore, useBreadcrumbStore),
    /**
     * Adjust the source link using the public config. We need to adjust
     * the link to source here because of the need to access the env variables
     * from the nuxt.config.ts file.
     */
    computedMenuList() {
      return this.menuList.map((menu) => {
        if (menu.name.toLowerCase() !== 'services') return menu

        const groups = menu.groups.map((group) => {
          if (group.name.toLowerCase() !== 'subscriptions') return group

          const children = group.children.map((child) => {
            if (child.name.toLowerCase() !== 'source') return child
            const href = this.$config.public.sourceUrl
            return { ...child, href }
          })

          return { ...group, children }
        })

        return { ...menu, groups }
      })
    },

    activeMenuIndex() {
      const { fullPath } = this.$route

      const NEWSLETTERS_PATH = [
        'newsletter-signup',
        'price-spotlight-signup',
        'industry-reports',
        'policy-newsletter',
        'copper-briefing',
      ]

      if (fullPath === '/') return

      const index = this.computedMenuList.findIndex((item) => {
        if (item.groups.length === 0) return item.href === fullPath

        const paths = fullPath.split('/').filter((path) => path)
        return item.groups
          .filter(
            (group) =>
              (group.href && paths.includes(group.href.replace('/', ''))) ||
              group.children?.length > 0,
          )
          .map((group) => group.children)
          .map((children) =>
            children.map((child) => child?.children ?? child).flat(),
          )
          .flat()
          .some((subItem) => {
            const market = subItem?.href?.trim()?.split('/')?.at(1)
            return paths.includes(market) ?? NEWSLETTERS_PATH.includes(fullPath)
          })
      })

      if (index >= 0) this.getActiveSubItem(this.computedMenuList[index])
      return index
    },
  },

  methods: {
    getActiveSubItem(menu) {
      this.breadcrumbStore.clearBreadcrumb()
      if (menu.name?.toLowerCase() !== 'markets') {
        this.breadcrumbStore.addItemBreadcrumb(menu.name)
      }

      if (menu.groups.length === 0) return

      const activeSubMenu = this.menuStore.getActiveSubMenu
      const activeMenu = this.menuStore.activeMenu

      if (activeMenu !== null) {
        this.breadcrumbStore.addItemBreadcrumb(activeMenu.label)
      }

      if (!activeSubMenu) return
      if (
        !(!this.$bmAuth.isAuthenticated() && activeSubMenu === 'Chart builder')
      ) {
        this.breadcrumbStore.addItemBreadcrumb(activeSubMenu)
      }
    },
    getKey(menu) {
      if (!menu.groups.length) return menu.name

      const childrenLength = menu.groups.flatMap(
        (group) => group.children,
      ).length

      return `${menu.name}_${childrenLength}`
    },
  },
}
</script>
