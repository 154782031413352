<template>
  <Modal
    name="signIn"
    data-testid="signin-modal"
    modal-overlay-classes="sm:px-big sm:pb-large px-0 pb-0 pt-12 md:p-0 sm:justify-center justify-start"
    modal-classes="md:max-w-article-container-medium relative mx-auto bg-white h-fit p-0"
    :is-open="modalOpen"
    @close="closeModal"
  >
    <SignInForm :image="image" />
  </Modal>
</template>

<script setup lang="ts">
import Modal from '~/components/common/ModalV2.vue'
import SignInForm from '~/components/SignInForm.vue'
import { LOGIN_IMAGES } from '~/src/constants/auth'
import { random } from '~/utils/random'
import { cloudinaryBuildRoute } from '~/src/utils/cloudinary'
import { useScreenSize } from '~/composables/useScreenSize'
import { useModalStore } from '~/stores/modal'

const MODAL_NAME = 'signIn'

const image = ref<string>('')
const modalStore = useModalStore()
const { close } = modalStore
const { modals } = storeToRefs(modalStore)
const { isNotDesktop } = useScreenSize()

const closeModal = () => close(MODAL_NAME)
const modalOpen = computed(() => modals.value[`${MODAL_NAME}Open`])

onMounted(() => {
  const rand = random()
  const size = LOGIN_IMAGES.length
  const randRound = Math.floor(rand * size)
  const modifiers = isNotDesktop.value ? ['w_500', 'h_300'] : ['w_400', 'h_500']

  image.value = cloudinaryBuildRoute(LOGIN_IMAGES[randRound], modifiers)
})
</script>
